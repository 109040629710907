<div class="component-not-found">
  <div class="component-not-found__animation">
    <ng-lottie [options]="options"></ng-lottie>
  </div>
  <div class="component-not-found__before-heading">{{ 'notFoundPage.title' | translate }}</div>
  <h3 class="component-not-found__heading" [innerHTML]="'notFoundPage.heading' | translate | trustHtml"></h3>
  <div class="component-not-found__content">
    <p>{{ 'notFoundPage.content' | translate }}</p>
  </div>
  <button bds-button intent="cta" [routerLink]="['/']">{{ 'notFoundPage.btnBack' | translate }}</button>
</div>
