import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetInvoicesQuery } from '@boels-state/actions/query.action';
import { SortBy } from '@boels-core/models/sort.model';
import { Filter } from '@boels-core/models/filter.model';
import { Pagination } from '@boels-experience/navigation';

export interface InvoicesQuery {
  sort: SortBy;
  pagination: Pagination;
  culture: string;
  debtorNumbers: Array<string>;
  searchTerm: string;
  filterParam: Filter;
}

export interface QueryStateModel {
  latestInvoicesQuery: InvoicesQuery;
}

@State<QueryStateModel>({
  name: 'query',
  defaults: {
    latestInvoicesQuery: null,
  },
})
@Injectable()
export class QueryState {
  @Selector()
  public static selectLatestInvoicesQuery(state: QueryStateModel): InvoicesQuery {
    return state?.latestInvoicesQuery;
  }

  @Action(SetInvoicesQuery)
  public setInvoicesQuery({ patchState }: StateContext<QueryStateModel>, action: SetInvoicesQuery): void {
    patchState({
      latestInvoicesQuery: structuredClone(action.latestInvoicesQuery),
    });
  }
}
