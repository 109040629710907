<div
  mat-fab
  [matMenuTriggerFor]="menu"
  #trigger="matMenuTrigger"
  [matMenuTriggerRestoreFocus]="false"
  class="account-indicator"
  aria-label="User menu"
>
  @if (userStoreService.userInitials$ | async; as initials) {
    <span class="account-indicator__label">{{ initials }}</span>
  } @else {
    <img src="assets/images/user.svg" alt="User menu" loading="lazy" />
  }
</div>

<mat-menu #menu="matMenu" class="user-menu_panelClass" [overlapTrigger]="false">
  @if (accounts$ | async; as accounts) {
    @if (activeAccounts$ | async) {
      <button class="button-reset menu-item_button menu-item_button--debtor-selector" boelsDebtorSelector>
        <fa-icon [icon]="faBuilding"></fa-icon>
        <div class="menu-item_content">
          <span class="menu-item_title" [tooltip]="'debtor.debtors' | translate"
            >{{ 'debtor.debtors' | translate }}
          </span>
          <span class="menu-item_subtitle"><boels-selected-debtor></boels-selected-debtor></span>
        </div>
      </button>
      <button class="button-reset menu-item_button" [routerLink]="[AppRoutesUrisConst.Account()]">
        <fa-icon [icon]="faUser"></fa-icon>
        <div class="menu-item_content">
          <span class="menu-item_title" [tooltip]="'user.account' | translate">{{ 'user.account' | translate }} </span>
          <span class="menu-item_subtitle">{{ userStoreService.userName$ | async }}</span>
        </div>
      </button>
      <button class="button-reset menu-item_button" (click)="openDialog()">
        <fa-icon [icon]="faGlobe"></fa-icon>
        <div class="menu-item_content">
          <span class="menu-item_title" [tooltip]="'languageSelector.CountryAndLanguage' | translate"
            >{{ 'languageSelector.CountryAndLanguage' | translate }}
          </span>
          <span class="menu-item_subtitle">{{ selectedLanguage }}</span>
        </div>
      </button>
      <a
        class="button-reset menu-item_button"
        href="{{ baseBoelsUrl }}/{{ localizationService.localization }}"
        target="_blank"
        [qa]="'user-menu-dotcom-link'"
      >
        <fa-icon [icon]="faShoppingBasket"></fa-icon>
        <div class="menu-item_content">
          <span class="menu-item_title" [tooltip]="'languageSelector.rentEquipmentTitle' | translate"
            >{{ 'languageSelector.rentEquipmentTitle' | translate }}
          </span>
          <span class="menu-item_subtitle">{{ 'languageSelector.rentEquipmentSubtitle' | translate }}</span>
        </div>
      </a>
      @if (showEquipmentPool) {
        <a
          class="button-reset menu-item_button"
          [href]="equipmentPoolUrl"
          target="_blank"
          [qa]="'user-menu-equipment-pool-link'"
        >
          <fa-icon [icon]="faKey"></fa-icon>
          <div class="menu-item_content">
            <div class="menu-item_title" [tooltip]="'equipmentPool.equipmentPool' | translate">
              <span>{{ 'equipmentPool.equipmentPool' | translate }}</span>
            </div>
            <span class="menu-item_subtitle"
              >{{ 'equipmentPool.goToEquimentPool' | translate }}&nbsp;<fa-icon [icon]="faExternalLink"></fa-icon
            ></span>
          </div>
        </a>
      }
      <hr />
    }
  }

  <button class="button-reset menu-item_button" (click)="logout()">
    <fa-icon [icon]="faArrowRightFromBracket" [attr.alt]="'logOut' | translate"></fa-icon>
    <span class="menu-item_subtitle">{{ 'logOut' | translate }} </span>
  </button>
</mat-menu>
