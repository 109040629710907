import { Component, EventEmitter, inject, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Debtor } from 'src/app/core/models/user.model';
import { UserStoreService } from 'src/app/core/services/user.store.service';
import { DestroyReferenceDirective } from 'src/app/shared/directives/destroy-reference.directive';
import { SelectedDebtorComponent } from '@boels-shared/components/selected-debtor/selected-debtor.component';
import { MatIcon } from '@angular/material/icon';
import { DebtorSelectorDirective } from '@boels-shared/directives/debtor-selector.directive';
import { AsyncPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-debtor-selector',
  templateUrl: './debtor-selector.component.html',
  styleUrls: ['./debtor-selector.component.scss'],
  standalone: true,
  imports: [DebtorSelectorDirective, MatIcon, SelectedDebtorComponent, AsyncPipe, FaIconComponent],
})
export class DebtorSelectorComponent extends DestroyReferenceDirective {
  @Output() public outputModalClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly userStoreService = inject(UserStoreService);

  public accounts$: Observable<ReadonlyArray<Debtor>> = this.userStoreService.accounts$;

  public isDialogActive: boolean = false;
  protected readonly faBuilding = faBuilding;
}
