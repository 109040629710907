import { Component, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { AppRoutesUrisConst } from '@boels-shared/constants/app-routes-uris.const';
import { firstValueFrom, Observable } from 'rxjs';
import { Debtor } from 'src/app/core/models/user.model';
import { LocalizationService } from 'src/app/core/services/localization.service';
import { UserService } from 'src/app/core/services/user.service';
import { UserStoreService } from 'src/app/core/services/user.store.service';
import { DestroyReferenceDirective } from 'src/app/shared/directives/destroy-reference.directive';
import { StorageKey } from 'src/app/shared/enums/storage.enum';
import { baseBoelsUrl, environment } from '../../../../../environments/environment';
import { DialogLanguageSelectorComponent } from '@boels-core/components/language-selector/dialog-language-selector/dialog-language-selector.component';
import { SmartControlAccessService } from '@boels-core/services/smart-control-access.service';
import JSON from 'highcharts/dashboards/es-modules/Dashboards/JSON';
import { TranslateModule } from '@ngx-translate/core';
import { QaHooksDirective } from '@boels-experience/core';
import { RouterLink } from '@angular/router';
import { SelectedDebtorComponent } from '@boels-shared/components/selected-debtor/selected-debtor.component';
import { TooltipDirective } from '@boels-shared/directives/tooltip.directive';
import { MatIcon } from '@angular/material/icon';
import { DebtorSelectorDirective } from '@boels-shared/directives/debtor-selector.directive';
import { AsyncPipe } from '@angular/common';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import Array = JSON.Array;
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  faArrowRightFromBracket,
  faBuilding,
  faExternalLink,
  faGlobe,
  faKey,
  faShoppingBasket,
  faShoppingCart,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  standalone: true,
  imports: [
    MatMenuTrigger,
    MatMenu,
    DebtorSelectorDirective,
    MatIcon,
    TooltipDirective,
    SelectedDebtorComponent,
    RouterLink,
    QaHooksDirective,
    AsyncPipe,
    TranslateModule,
    FaIconComponent,
  ],
})
export class UserMenuComponent extends DestroyReferenceDirective implements OnInit {
  public readonly userStoreService = inject(UserStoreService);
  private readonly userService = inject(UserService);
  private readonly dialog = inject(MatDialog);
  public readonly localizationService = inject(LocalizationService);
  private readonly smartControlAccessService = inject(SmartControlAccessService);

  public accounts$: Observable<ReadonlyArray<Debtor>> = this.userStoreService.accounts$;
  public activeAccounts$: Observable<Array<any>> = this.userStoreService.activeAccounts$;
  public selectedLanguage!: string;
  public AppRoutesUrisConst = AppRoutesUrisConst;

  private readonly selectedCountry: string = localStorage.getItem(StorageKey.CULTURE)?.split('-')[1] || '';

  protected readonly baseBoelsUrl = baseBoelsUrl;

  public equipmentPoolUrl: string = environment.equipmentPoolUrl;
  public showEquipmentPool: boolean = false;

  constructor() {
    super();

    this.localizationService.country$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.selectedLanguage = localStorage.getItem(StorageKey.COUNTRY_LANGUAGE) || '';
    });
  }

  public logout(): void {
    this.userService.logoutUser();
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(DialogLanguageSelectorComponent, {
      autoFocus: false,
      disableClose: !this.selectedCountry,
      panelClass: 'modal-language-selector',
      data: { normalizedCountryName: this.selectedCountry },
      restoreFocus: false,
    });

    dialogRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  private async hasEquipmentPoolAccess(): Promise<boolean> {
    const access = await firstValueFrom(this.smartControlAccessService.getSmartControlAccess());
    return !!access.ACCESS_EQUIPMENT_POOLS;
  }

  public async ngOnInit(): Promise<void> {
    this.showEquipmentPool = await this.hasEquipmentPoolAccess();
  }

  protected readonly faBuilding = faBuilding;
  protected readonly faUser = faUser;
  protected readonly faGlobe = faGlobe;
  protected readonly faShoppingCart = faShoppingCart;
  protected readonly faShoppingBasket = faShoppingBasket;
  protected readonly faKey = faKey;
  protected readonly faArrowRightFromBracket = faArrowRightFromBracket;
  protected readonly faExternalLink = faExternalLink;
}
