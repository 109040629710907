import { Component, inject, OnInit } from '@angular/core';
import { SmartControlAccessService } from '@boels-core/services/smart-control-access.service';
import { AppRoutesUrisConst } from '@boels-shared/constants/app-routes-uris.const';
import { DestroyReferenceDirective } from '@boels-shared/directives/destroy-reference.directive';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Link } from '../../models/link.model';
import { LocalizationService } from '../../services/localization.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  standalone: true,
  imports: [RouterLinkActive, RouterLink, MatIcon, TranslateModule, FaIconComponent],
})
export class NavigationComponent extends DestroyReferenceDirective implements OnInit {
  private readonly smartControlAccessService = inject(SmartControlAccessService);
  public readonly localizationService = inject(LocalizationService);
  public equipmentPoolUrl: string = environment.equipmentPoolUrl;
  public showEquipmentPool: boolean = false;

  public nodes: Array<Link> = [
    { title: 'Overview', icon: 'overview', uri: AppRoutesUrisConst.Dashboard() },
    { title: 'My Products', icon: 'my-products', uri: AppRoutesUrisConst.Products() },
    { title: 'Contracts', icon: 'contracts', uri: AppRoutesUrisConst.Contracts() },
    { title: 'Invoices', icon: 'invoices', uri: AppRoutesUrisConst.Invoices() },
  ];
  public AppRoutesUrisConst = AppRoutesUrisConst;

  private async hasEquipmentPoolAccess(): Promise<boolean> {
    const access = await firstValueFrom(this.smartControlAccessService.getSmartControlAccess());
    return !!access.ACCESS_EQUIPMENT_POOLS;
  }

  public async ngOnInit(): Promise<void> {
    this.showEquipmentPool = await this.hasEquipmentPoolAccess();
  }

  protected readonly faExternalLink = faExternalLink;
}
