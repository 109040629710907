<nav>
  <ul class="nav-list">
    @for (node of nodes; track node) {
      <li class="nav-item">
        <a [routerLink]="node.uri" routerLinkActive="is-active" [attr.data-text]="'menu.' + node.icon | translate">
          {{ 'menu.' + node.icon | translate }}
        </a>
      </li>
    }
    @if (showEquipmentPool) {
      <li class="nav-item">
        <a [href]="equipmentPoolUrl" target="_blank" [attr.data-text]="'equipmentPool.equipmentPool' | translate">
          <div class="nav__equipment-pool">
            <span>{{ 'equipmentPool.equipmentPool' | translate }}</span>
            &nbsp;<fa-icon [icon]="faExternalLink"></fa-icon>
          </div>
        </a>
      </li>
    }
  </ul>
</nav>
