import { importProvidersFrom, Provider, EnvironmentProviders } from '@angular/core';
import { environment } from '../environments/environment';
import { ToggleLoading } from '@boels-state/actions/shared.action';
import { isAppRunningLocally } from '@boels-shared/utils/helper.utils';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { DebtorState } from './state/state/debtors.state';
import { UserState } from './state/state/user.state';
import { SharedState } from './state/state/shared.state';
import { NgxsModule, getActionTypeFromInstance } from '@ngxs/store';
import { QueryState } from '@boels-state/state/query.state';

export const provideStore = (): Array<Provider | EnvironmentProviders> => [
  importProvidersFrom(
    NgxsModule.forRoot([SharedState, UserState, DebtorState, QueryState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      keys: [DebtorState],
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: !isAppRunningLocally(),
      // We want to filter out NGXS logger for loading state trigger as it's too much.
      filter: (action) => getActionTypeFromInstance(action) !== ToggleLoading.type,
    })
  ),
];
